var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TopBar',{attrs:{"title":_vm.title}},[_c('template',{slot:"actions"},[(_vm.canAddTrainer)?_c('v-btn',{staticClass:"mb-0",attrs:{"color":"primary","to":{ name: 'Trainer Create' }}},[_vm._v(" "+_vm._s(_vm.$t('btn.add_trainer'))+" ")]):_vm._e()],1)],2),_c('v-container',{attrs:{"id":"trainer-list","fluid":"","tag":"section"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-material-card',{staticClass:"px-5 pt-3 mb-15",attrs:{"dark":_vm.$vuetify.theme.dark,"inline":"","color":"primary","icon":"mdi-clipboard-text"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('v-card-title',{staticClass:"pt-0 pb-2"},[_c('div',[_vm._v(_vm._s(_vm.$t('trainer.list.title')))])])]},proxy:true}])},[_c('v-data-table',{staticClass:"elevation-1 my-3",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.trainerList,"options":_vm.paginationOptions,"hide-default-footer":""},on:{"update:sort-by":_vm.onSortBy,"update:sort-desc":_vm.onSortDesc},scopedSlots:_vm._u([{key:"header.lastName",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('ListTextFilter',{attrs:{"translation":_vm.$t('trainer.list.filter.lastName'),"value":_vm.filters.lastName},on:{"filter-list":_vm.filterByLastName}})],1)]}},{key:"header.firstName",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('ListTextFilter',{attrs:{"translation":_vm.$t('trainer.list.filter.firstName'),"value":_vm.filters.firstName},on:{"filter-list":_vm.filterByFirstName}})],1)]}},{key:"header.areas",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('ListSelectFilter',{attrs:{"multiple":"","translation":_vm.$t('trainer.list.filter.area'),"value":_vm.filters.areaSearched,"items":_vm.listAreas,"item-text":function (item) { return _vm.$t('form.area', {
                    name: item.name,
                  }); }},on:{"filter-list":_vm.filterByArea}})],1)]}},{key:"item.areas",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.concatAreas(item.areas))+" ")]}},{key:"header.trainingType",fn:function(ref){
                  var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('ListSelectFilter',{attrs:{"multiple":"","translation":_vm.$t('trainer.list.filter.training_type'),"value":_vm.filters.trainingTypeSearched,"items":_vm.trainingTypesList,"item-text":function (item) { return item.name; }},on:{"filter-list":_vm.filterByTrainingType}})],1)]}},{key:"item.trainingType",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.concatTrainingTypesNames(item.trainingType))+" ")]}},{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"text-center ma-2 rounded"},[_c('v-avatar',{staticClass:"rounded-lg",attrs:{"tile":"","color":"success"}},[_c('v-icon',{on:{"click":function($event){return _vm.gotToTrainerForm(item)}}},[_vm._v(" mdi-pencil ")])],1)],1)]}},{key:"footer",fn:function(){return [_c('Pagination',{attrs:{"pagination-options":_vm.paginationOptions,"nb-pages":_vm.nbPages},on:{"changePaginationOptions":function($event){_vm.paginationOptions = $event}}})]},proxy:true}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }